// Fill and stroke colors for processed job polygons
const POLYGON_STYLES = {
  working_area: { fillColor: '#ffe903', strokeColor: '#00000000', zIndex: 11 },
  working_area_outer_perimeter: { fillColor: '#00000000', strokeColor: '#FF3B30', zIndex: 12 },
  mowing_area: { fillColor: '#41c000', strokeColor: '#60ff00', zIndex: 12 },
  keep_out_zone_working_areas: { fillColor: '#ffe903', strokeColor: '#00000000', zIndex: 11 },
  keep_out_zones: { fillColor: '#00000000', strokeColor: '#FF3B30', zIndex: 12 },
}

// Logic for rendering a job boundary on a Google Map as a Polygon.
export default class ProcessedJob {
  constructor(processedJob) {
    this.jobId = processedJob.job_id
    // Contains an array of processed job polygons
    this.processedJobPolygons = []

    let _this = this

    $(window).on("map-rendered", function(event) {
      if (map.currentJob.jobId == _this.jobId) {
        map.currentJob.processedJob = _this
        _this.renderProcessedJob(processedJob.areas)
      }
    })

    if (map.gmap) {
      _this.renderProcessedJob(processedJob.areas)
    }
  }

  // Renders polygons for a processed job. Each polygon type is included in the `POLYGON_STYLES` const.
  renderProcessedJob(areas) {
    Object.entries(areas).forEach(([type, paths]) => {
      if (!paths) return
      if (type == 'keep_out_zone_working_areas' || type == 'keep_out_zones') {
        paths.forEach((path) => this.processedJobPolygon(type, path))
      } else {
        this.processedJobPolygon(type, paths)
      }
    })
  }

  show() {
    this.processedJobPolygons.forEach(polygon => polygon.setMap(map.gmap))
  }

  // Sets map to  processed job polygons.
  hide() {
    this.processedJobPolygons.forEach(polygon => polygon.setMap(null))
  }

  // Styles and renders each type of processed job polygon based on matching the type to a style using `POLYGON_STYLES`.
  processedJobPolygon(type, paths) {
    let styles = POLYGON_STYLES[type]
    let polygon = new google.maps.Polygon({
      map: map.gmap,
      paths: paths,
      fillColor: styles.fillColor,
      strokeColor: styles.strokeColor,
      strokeOpacity: 1,
      fillOpacity: 0.5,
      strokeWeight: 3,
      zIndex: styles.zIndex, // 11 for working areas to display above original job perimeter, 12 for others to display on top of working areas
      clickable: false
    })
    this.processedJobPolygons.push(polygon)
  }
}
